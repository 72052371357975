<template>
    <div class="bg-grey-2">
        <div class="col">
            <!-- Top area -->
            <q-card v-if="uberEvents" class="col no-box-shadow">
                <div class="flex justify-between items-center q-pa-md z-top bg-white" style="position: sticky; top:0; border-bottom: 1px solid #D4D4D4;">
                    <!-- Main heading -->
                    <div class="typography-h1">{{ $t('otc.title') }}</div>
                    <!-- Language switcher -->
                    <q-btn class="my-btn my-btn--secondary gt-sm" outline :label="$t('storefront.lang_other')" @click="toggleLanguage" />
                    <q-btn class="my-btn my-btn--secondary lt-md" outline :label="$t('storefront.lang_other__mobile')" @click="toggleLanguage" />
                </div>

                <!-- "Flattened event" filters -->
                <q-card-section class="row" style="background-color: #F9FAFB;">
                    <div class="checkbox__wrapper q-py-lg">
                        <div
                            class="otc__checkbox__wrapper row"
                            :class="{ 'active': !filterUETypes.length }"
                        >
                            <TypeAvatar data="vsechny" />
                            <div class="row justify-start items-center">
                                <p class="body-medium q-mb-none">{{ $t('otc.types_all') }}</p>
                            </div>
                            <q-checkbox
                                class="otc__checkbox"
                                :value="!filterUETypes.length"
                                :readonly="!filterUETypes.length"
                                @input="filterUETypes = []"
                            />
                        </div>
                        <div class="col-3" v-if="venues">
                            <FilteredSelect
                                popup-content-class="my-input-select__options"
                                class="select-venues my-input-select full-width col-md-4 q-px-none"
                                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                dense 
                                outlined
                                use-input
                                fill-input
                                hide-selected
                                clearable
                                otc
                                :label="$t('otc.venue')"
                                :filterFn="venueFilterFn"
                                :input-debounce="0"
                                v-model="filterVenue"
                                :options="venues"
                                option-value="id"
                                :option-label="o => loc(o.name)" 
                            />
                        </div>
                        <div
                            v-for="type in ueTypes"
                            :key="type"
                            class="otc__checkbox__wrapper row"
                            :class="{ 'active': filterUETypes.includes(type) }"
                        >
                            <TypeAvatar :data="type" />
                            <div class="row justify-start items-center">
                                <p class="body-medium q-mb-none">{{ $tc(`event.type.${type}`, 2) }}</p>
                            </div>
                            <q-checkbox
                                class="otc__checkbox"
                                v-model="filterUETypes"
                                :val="type"
                            />
                        </div>
                    </div>
                </q-card-section>

                <!-- "Flattened event" selector -->
                <q-card-section style="border-bottom: 1px solid #D4D4D4; border-top: 1px solid #D4D4D4;">
                    <div class="row justify-center q-py-lg">
                        <q-select
                            popup-content-class="my-input-select__options"
                            class="my-input-select full-width col-md-4"
                            style="max-width: 880px;"
                            dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                            v-model="flattenedEvent"
                            dense
                            outlined
                            :label="$t('otc.flattened_event')"
                            :options="filteredFlattenedEvents"
                            :option-label="getFlattenedEventLabel"
                            :disable="ticketsBlocked || orderPlaced"
                            use-input
                            hide-selected
                            fill-input
                            clearable
                            :input-debounce="50"
                            :class="['col-6', availabilityBlocker && 'unavailable']"

                            @filter="onFlattenedEventFilter"
                        >
                            <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                    <q-item-section :class="!selected && opt.blocker && 'text-grey-6'">
                                        <q-item-label>{{ getFlattenedEventLabel(opt) }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                    </div>
                </q-card-section>
            </q-card>

            <!-- N/A notice -->
            <q-card v-if="flattenedEvent && availabilityBlocker" :class="mockHide ? 'bg-grey-2 text-grey-7 text-italic' : 'q-pa-lg bg-grey-2 text-h5 text-center'">
                <q-card-section v-if="availabilityBlocker === 'past'">
                    {{ $t('storefront.not_for_sale_past_event') }}
                </q-card-section>
                <q-card-section v-else-if="availabilityBlocker === 'too_early'">
                    {{ $t('storefront.not_for_sale_too_early') }}
                    {{ mockHide ? `(Až od ${formatDate(getPublicFrom())}.)` : '' }}
                </q-card-section>
                <q-card-section v-else-if="availabilityBlocker === 'too_late'">
                    {{ $t('storefront.not_for_sale_too_late') }}
                    {{ mockHide ? `(Pouze do ${formatDate(getPublicTo())}.)` : '' }}
                </q-card-section>
                <q-card-section v-else-if="availabilityBlocker === 'sold_out'">
                    {{ $t('storefront.all_sold_out') }}
                </q-card-section>
                <q-card-section v-else-if="mockHide && availabilityBlocker === 'not_ready'">
                    {{ $t('storefront.not_ready_to_sell') }}
                </q-card-section>
                <q-card-section v-else>
                    {{ $t('storefront.not_for_sale_generic') }}
                </q-card-section>
            </q-card>

            <!-- Storefront-like area -->
            <q-card
                v-if="flattenedEvent && !availabilityBlocker"
                class="no-margin-sm q-px-md q-py-lg q-mx-md q-mt-xl bg-white bordered-box"
                style="border: 1px solid #D4D4D4;"
            >
                <!-- (Uber-)event details  -->
                <q-card-section class="q-mt-sm">
                    <div class="q-mb-md">
                        <UberEventChip :data="uberEvent" />
                    </div>
                    <span class="small-label">{{$t('label.event_title')}}</span>
                    <div
                        class="typography-h2 q-mb-sm"
                        :class="['text-h5', availabilityBlocker && 'text-grey-6']"
                    >
                        {{ loc(uberEvent.name) }}

                        {{(event && loc(event.name)) && `- ${loc(event.name)}`}}
                    </div>
                    <div class="typography-h3 text-grey-5 q-mb-md" v-if="loc(uberEvent.subtitle)">{{ loc(uberEvent.subtitle) }}</div>
                    <div class="flex">
                        <div class="q-mb-md">
                            <span class="small-label block q-mb-sm">{{ $t('label.location') }}</span>
                            <div class="description">{{ formattedVenue }}</div>
                        </div>
                        <div class="q-ml-lg q-mb-md" v-if="formattedDate">
                            <template v-if="showDateSelect">
                                <q-select dense outlined class="inline q-ml-xs text-body1" style="border-radius: 0px !important;" :value="event" :options="dateSelectEvents" :disable="finalized"
                                            option-value="id" :option-label="o => formatDate(o.from)" @input="goToEvent($event)"
                                            :class="availabilityBlocker && 'unavailable'"
                                >
                                    <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                        <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                            <q-item-section :class="!selected && sfAvailabilityBlocker(uberEvent, opt) && 'text-grey-6'">
                                                <q-item-label>{{ formatDate(opt.from) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                            </template>
                            <template v-else>
                                <span class="small-label block q-mb-sm">{{ $t('label.event_date') }}</span>
                                <div class="description">  {{ formattedDate }}</div>
                            </template>

                        </div>
                    </div>
                    <div v-if="uberEvent.promoterRef">
                        <span class="small-label block q-mb-sm q-pt-sm">{{ $t('label.organizer') }}</span>
                        <div class="description">  {{findById(promoters, uberEvent.promoterRef).title}}</div>
                    </div>
                </q-card-section>

                <!-- Warning message -->
                <q-card-section v-if="warningMessage" class="q-mt-md text-orange text-subtitle1 text-bold text-italic" v-html="warningMessage" />

                <!-- Error message -->
                <q-card-section v-if="errorMessage" class="q-mt-md text-red text-subtitle1 text-bold text-italic" v-html="errorMessage" />

                <!-- <q-card-section class="q-mt-lg">
                    <q-timeline color="primary">
                        <q-timeline-entry :title="$t('storefront.tickets')">
                            <q-card :flat="!es.event" :class="['q-mb-md fields', es.event ? 'bg-lime-2' : 'bg-transparent']" v-for="es in eventSelections" :key="es.event ? es.event.id : 0">
                                <q-card-section :class="[es.event ? 'q-px-sm q-py-sm' : 'q-pa-none']">
                                    <div class="q-gutter-sm">
                                        <div :class="{ 'text-grey': mockHide && es.event && !isEventPublic(es.event) }">
                                            <span class="text-bold">{{ es.event && getEventSelectionName(es.event) }}</span>
                                            <template v-if="mockHide && es.event && !isEventPublic(es.event)">
                                                <q-icon name="warning" color="warning" class="q-ml-xs" />
                                                {{ formatDateRange(getPublicFrom(es.event), getPublicTo(es.event)) }}
                                            </template>
                                        </div>
                                        <div v-for="ts in es.ticketSelections">
                                            <div class="row items-start q-col-gutter-sm">
                                                <q-select dense outlined v-model="ts.group" :label="getTGSelectLabel(es)"
                                                            :bottom-slots="!!getTGHint(ts.group)" hide-bottom-space :disable="ticketsBlocked || orderPlaced"
                                                            :class="{
                                                                'col-4': true,
                                                                'tg-non-public': mockHide && ts.group && !isTGPublic(es, ts.group),
                                                            }"
                                                            :readonly="tgSelectReadonly(es)" :hide-dropdown-icon="tgSelectReadonly(es)"
                                                            :options="getTicketGroups(es)" option-value="id" :option-label="getTGOptionLabel"
                                                            :option-disable="o => !getTGAvailableCount(o)">
                                                    <template v-slot:hint>
                                                        <div class="row" style="margin-left: -8px;">
                                                            <q-icon name="info" class="q-mr-xs" />
                                                            <span class="col">{{ getTGHint(ts.group) }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                                        <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                            <q-item-section :class="{
                                                                    'text-italic': !getTGAvailableCount(opt),
                                                                    'text-grey': !selected && !isTGPublic(es, opt),
                                                                }">
                                                                <q-item-label>{{ getTGOptionLabel(opt) }}</q-item-label>
                                                                <q-item-label v-if="mockHide && !isTGPublic(es, opt)" caption class="row items-center">
                                                                    <q-icon name="warning" color="warning" class="q-mr-xs" />
                                                                    {{ formatDateRange(getTGPublicFrom(es, opt), getTGPublicTo(es, opt)) }}
                                                                </q-item-label>
                                                            </q-item-section>
                                                        </q-item>
                                                    </template>
                                                </q-select>

                                                <q-select class="col-4" dense outlined emit-value map-options v-model="ts.discount" :label="$t('storefront.discount')"
                                                            :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || !getDiscountOptions(ts.group).length"
                                                            :options="getDiscountOptions(ts.group)" />

                                                <q-select class="col-4" dense outlined v-model="ts.count" :label="$t('storefront.count')"
                                                            :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || isRowRedundant(es, ts)"
                                                            :option-disable="o => !!o.length"
                                                            :options="getCountOptions(es, ts.group, ts.count)">
                                                    <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                                        <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                            <q-item-section :class="{ 'text-italic': opt.length }">
                                                                <q-item-label>{{ opt }}</q-item-label>
                                                            </q-item-section>
                                                        </q-item>
                                                    </template>
                                                </q-select>
                                            </div>
                                        </div>
                                    </div>
                                </q-card-section>
                            </q-card>

                            <div class="q-mt-md text-subtitle1">
                                <i18n path="storefront.total" tag="span">
                                    <span slot="count">{{ $tc('ticket', totalTicketCount) }}</span>
                                    <span slot="price"><strong>{{ $tc('price', totalPrice) }}</strong></span>
                                </i18n>
                            </div>

                            <div class="q-mt-md fields">
                                <q-btn class="full-width" color="primary"
                                        :label="$t(ticketsBlocked ? 'storefront.back' : 'storefront.next')"
                                        :disable="!readyToBlock || finalized" @click="ticketsBlocked ? releaseAndReturn() : blockAndProceed()" />
                            </div>

                            <div v-if="ticketsBlocked" class="q-mt-md fields">
                                {{ $t('storefront.tickets_blocked') }}<br>
                                <span class="text-subtitle1 text-bold">
                                    {{ $t('storefront.blocked_until') }}
                                    <span class="text-red">{{ blockTimeRemaining }}</span>
                                </span>
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry :title="$t('storefront.contact_info')" :color="ticketsBlocked ? null : 'grey'">
                            <div class="row q-col-gutter-sm fields" :class="ticketsBlocked ? null : 'text-grey'">
                                <q-input class="col-6" dense outlined v-model="contactInfo.firstName" :label="$t('storefront.first_name')" name="firstName" :disable="!ticketsBlocked || orderPlaced" />
                                <q-input class="col-6" dense outlined v-model="contactInfo.lastName" :label="$t('storefront.last_name')" name="lastName" :disable="!ticketsBlocked || orderPlaced" />
                                <q-input class="col-6" dense outlined v-model="contactInfo.email" :label="$t('storefront.email')" type="email" name="email" :disable="!ticketsBlocked || orderPlaced" />
                                <q-input class="col-6" dense outlined v-model="contactInfo.phone" :label="$t('storefront.phone')" type="tel" name="phone" :disable="!ticketsBlocked || orderPlaced" />
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry :title="$t('otc.submission')" :color="submitEnabled ? null : 'grey'">
                            <div v-if="!finalized" class="row q-col-gutter-md fields" :class="submitEnabled ? null : 'text-grey'">
                                <div>{{ $t('otc.submit_desc') }}</div>
                                <div class="col-12">
                                    <q-btn class="full-width" color="primary" :label="$t('otc.submit')" :disable="!submitEnabled" @click="placeAndFinalizeOrder" />
                                </div>
                            </div>
                            <div v-else class="row q-col-gutter-md fields">
                                <div class="q-gutter-sm">
                                    <div>{{ $t('otc.submitted_desc_1') }}</div>
                                    <div>{{ $t('otc.submitted_desc_2') }}</div>
                                </div>
                                <div class="col-6">
                                    <q-btn class="full-width" color="green" :label="$t('otc.view_tickets')" @click="downloadAndOpenPdf" />
                                </div>
                                <div class="col-6">
                                    <q-btn class="full-width" color="primary" :label="$t('otc.start_new_order')" @click="startNewOrder" />
                                </div>
                            </div>
                        </q-timeline-entry>

                        <q-timeline-entry class="hidden">
                        </q-timeline-entry>
                    </q-timeline>
                </q-card-section> -->

                <q-card-section class="bg-white q-mx-md no-margin-sm bordered-box">
                    <q-timeline color="primary">
                        <!-- Tickets -->
                        <q-timeline-entry class="my-timeline" >
                            <template #title>
                                <span class="typography-h2">
                                {{$t('storefront.tickets')}}
                                </span>
                            </template>
                            <p class="q-mb-lg">{{ $t('label.tickets-choose') }}</p>
                            <!-- Event selection -->
                            <q-card flat :class="[{'card-multiple q-mb-lg' : eventSelections.length > 1}]" v-for="(es, esIdx) in eventSelections" :key="es.event ? es.event.id : 0">
                                <q-card-section :class="[es.event ? 'q-px-sm q-py-sm' : 'q-pa-none']">
                                    <div >
                                        <div :class="{ 'text-grey': mockHide && es.event && !isEventPublic(es.event) }">
                                            <span class="typography-h3 q-mb-sm block" v-if="esIdx === 0 && eventSelections.length > 1 && uberEvent.type === 'festival' "> {{ loc(uberEvent.name) }}</span>
                                            <span class="typography-h3 q-mb-sm block">{{ es.event && getEventSelectionName(es.event) }}</span>
                                            <template v-if="mockHide && es.event && !isEventPublic(es.event)">
                                                <q-icon name="warning" color="warning" class="q-ml-xs" />
                                                {{ formatDateRange(getPublicFrom(es.event), getPublicTo(es.event)) }}
                                            </template>
                                        </div>
                                        <!-- Ticket selection -->
                                        <template v-for="(ts, tsIdx) in es.ticketSelections">
                                            <div
                                                :key="ts.id + '-' + ts.group"
                                                class="row items-start q-col-gutter-lg tickets-selection"
                                                :class="[{'q-mb-lg' : es.ticketSelections.length > 1}]"
                                            >
                                            <!-- v-if="hasAvailableSlots(ts)" -->
                                                <!-- Ticket group -->
                                                <div class="col-12 col-md-3">
                                                    <q-select
                                                        popup-content-class="my-input-select__options"
                                                        class="my-input-select full-width col-md-4"
                                                        dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                        input-debounce="0"
                                                        behavior="menu"
                                                        outlined v-model="ts.group"
                                                        :label="getTGSelectLabel(es)"
                                                        :bottom-slots="!!getTGHint(ts.group)" hide-bottom-space :disable="ticketsBlocked || orderPlaced || (mockHide && !getTicketGroups(es).length)"
                                                        :readonly="tgSelectReadonly(es)" :hide-dropdown-icon="tgSelectReadonly(es)"
                                                        :options="getTicketGroups(es)" option-value="id" :option-label="getTGOptionLabel"
                                                        :option-disable="o => !getTGAvailableCount(o)">
                                                        <template v-slot:hint>
                                                            <div class="row" style="margin-left: -8px;">
                                                                <q-icon name="info" class="q-mr-xs" />
                                                                <span class="col">{{ getTGHint(ts.group) }}</span>
                                                            </div>
                                                        </template>
                                                        <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                                            <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                                <q-item-section :class="{
                                                                        'text-italic': !getTGAvailableCount(opt),
                                                                        'text-grey': !selected && !isTGPublic(es, opt),
                                                                    }">
                                                                    <q-item-label>{{ getTGOptionLabel(opt) }}</q-item-label>
                                                                    <q-item-label v-if="mockHide && !isTGPublic(es, opt)" caption class="row items-center">
                                                                        <q-icon name="warning" color="warning" class="q-mr-xs" />
                                                                        {{ formatDateRange(getTGPublicFrom(es, opt), getTGPublicTo(es, opt)) }}
                                                                    </q-item-label>
                                                                </q-item-section>
                                                            </q-item>
                                                        </template>
                                                </q-select>
                                                </div>
                                                <!-- Discount -->
                                                <div class="col-12 col-md-3">
                                                <q-select
                                                    popup-content-class="my-input-select__options"
                                                    class="my-input-select full-width"
                                                    dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                    input-debounce="0"
                                                    behavior="menu"
                                                    outlined
                                                    emit-value map-options v-model="ts.discount" :label="$t('storefront.discount')"
                                                    :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || !getDiscountOptions(ts.group).length"
                                                    :options="getDiscountOptions(ts.group)" />
                                                </div>
                                                <!-- Ticket count -->
                                                <div class="col-12 col-md-3">
                                                    <q-select
                                                        popup-content-class="my-input-select__options"
                                                        class="my-input-select full-width col-md-4"
                                                        dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                        input-debounce="0"
                                                        behavior="menu"
                                                        outlined v-model="ts.count" :label="$t('storefront.count')"
                                                        :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || isRowRedundant(es, ts)"
                                                        :option-disable="o => !!o.length"
                                                        :options="getCountOptions(es, ts.group, ts.count)"
                                                    >
                                                        <template v-slot:option="{ index, opt, itemProps, itemEvents }">
                                                            <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                                <q-item-section :class="{ 'text-italic': opt.length }">
                                                                    <q-item-label>{{ opt }}</q-item-label>
                                                                </q-item-section>
                                                            </q-item>
                                                        </template>
                                                    </q-select>
                                                </div>
                                                <div class="col-md-1 col-12 storefront-clear-btn" v-if="getCountOptions(es, ts.group, ts.count).length && ts.count && !ticketsBlocked && !orderPlaced">
                                                <q-icon @click="clearSelected(ts,es, esIdx, tsIdx)" size="20" class="block q-mt-lg q-ml-lg cursor-pointer gt-sm" color="gray" name="svguse:/icons.svg#trash"/>
                                                    <q-btn  class="my-btn my-btn--secondary lt-md empty full-width no-shadow mobile-trash" @click="clearSelected(ts,es, esIdx, tsIdx)" size="20"  name="svguse:/icons.svg#trash">
                                                        <q-icon size="20" class="block" color="gray" name="svguse:/icons.svg#trash"/>
                                                    </q-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </q-card-section>
                            </q-card>

                            <!-- Total -->
                            <div class="q-mt-md">
                                <i18n path="storefront.total" tag="span">
                                    <span class="body-medium" slot="count">{{ $tc('ticket', totalTicketCount) }}</span>
                                    <span class="body-medium" slot="price">{{ $tc('price', formatThousands(totalPrice)) }}</span>
                                </i18n>
                            </div>

                            <!-- Next/back button -->
                            <div class="row fields">
                                <div class="q-mt-md fields col-12 col-md-3">
                                    <q-btn
                                        class="my-btn my-btn--primary full-width" color="black"
                                    :label="$t(ticketsBlocked ? 'storefront.back' : 'storefront.next')"
                                    :disable="!readyToBlock" @click="ticketsBlocked ? releaseAndReturn() : blockAndProceed()" />
                                </div>
                            </div>

                            <!-- Block timer -->
                            <div v-if="ticketsBlocked" class="q-mt-md fields gt-sm">
                                {{ $t('storefront.tickets_blocked') }}<br>
                                <span class="text-subtitle1 text-bold">
                                    {{ $t('storefront.blocked_until') }}
                                    <span class="text-red">{{ blockTimeRemaining }}</span>
                                </span>
                            </div>
                        </q-timeline-entry>

                        <!-- Contact info -->
                        <q-timeline-entry class="my-timeline"  :color="ticketsBlocked ? null : 'grey'">
                            <template #title>
                                <span class="typography-h2" :class="{'text-grey-5':!ticketsBlocked}">
                                    {{$t('storefront.contact_info')}}
                                </span>
                            </template>
                            <p class="q-mb-lg" :class="{'text-grey-5':!ticketsBlocked}">{{$t('label.contact-details')}}</p>

                            <div class="row q-col-gutter-md fields" :class="ticketsBlocked ? null : 'text-grey'">
                                <div class="col-12 col-md-4">
                                    <q-input
                                        :rules="[val=>!!val || ''] "
                                        class="my-input col-6 required" outlined v-model="contactInfo.firstName" :label="$t('storefront.first_name')" name="firstName" :disable="!ticketsBlocked || orderPlaced" />
                                </div>
                                <div class="col-12 col-md-4">
                                    <q-input
                                        :rules="[val=>!!val || ''] "
                                        class="my-input required col-6"  outlined v-model="contactInfo.lastName" :label="$t('storefront.last_name')" name="lastName" :disable="!ticketsBlocked || orderPlaced" />
                                </div>
                                <div class="col-1"></div>
                                <div class="col-12 col-md-4">
                                    <q-input
                                        :rules="[val=>isEmail(val) || 'Zadejte platnou e-mailovou adresu'] "
                                        class="my-input required col-6" outlined v-model="contactInfo.email" :label="$t('storefront.email')" type="email" name="email" :disable="!ticketsBlocked || orderPlaced" />
                                </div>
                                <div class="col-12 col-md-4">
                                    <q-input
                                        :rules="[val=>isPhone(val) || 'Zadejte platné telefonní číslo'] "
                                        class="my-input required col-6" outlined v-model="contactInfo.phone" :label="$t('storefront.phone')" type="tel" name="phone" :disable="!ticketsBlocked || orderPlaced" />
                                </div>
                            </div>
                        </q-timeline-entry>

                        <!-- Payment -->
                        <q-timeline-entry class="my-timeline" :color="submitEnabled ? null : 'grey'">
                            <template #title>
                                <span class="typography-h2" :class="{'text-grey-5':!submitEnabled}">
                                    {{$t('storefront.payment')}}
                                </span>
                            </template>

                            <div v-if="!finalized" class="" :class="submitEnabled ? null : 'text-grey-5'">
                                <div>{{ $t('storefront.payment_desc') }}</div>
                                <div class="q-mt-md fields row">
                                    <div class="col-12 col-md-3">
                                        <q-btn
                                            class="my-btn my-btn--primary full-width"
                                        color="black"
                                        :label="$t('otc.submit')"
                                        :disable="!submitEnabled" @click="placeAndFinalizeOrder" />
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-else class="row q-col-gutter-md fields">
                                <div class="q-gutter-sm">
                                    <div>{{ $t('otc.submitted_desc_1') }}</div>
                                    <div>{{ $t('otc.submitted_desc_2') }}</div>
                                </div>
                                <div class="col-6">
                                    <q-btn class="full-width" color="green" :label="$t('otc.view_tickets')" @click="downloadAndOpenPdf" />
                                </div>
                                <div class="col-6">
                                    <q-btn class="full-width" color="primary" :label="$t('otc.start_new_order')" @click="startNewOrder" />
                                </div>
                            </div> -->
                        </q-timeline-entry>

                        <q-timeline-entry class="hidden">
                        </q-timeline-entry>
                    </q-timeline>
                </q-card-section>
            </q-card>

            <!-- Loading message -->
            <!-- <q-card v-if="!uberEvent && loading" class="q-pa-lg bg-grey-2 text-h4 text-center">
                <q-card-section>
                    Načítám událost...
                </q-card-section>
            </q-card> -->

            <!-- Soft 404 -->
            <!-- <q-card v-if="!uberEvent && !loading && !availabilityBlocker" /> -->
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

import Helpers from '@/mixins/Helpers';

export default {
    data() {
        return {
            // loading: true,
            populating: false,
            filterUETypes: [],
            filterVenue: null,
            flattenedEvent: null,
            feTextFilterFn: null,
            eventSelections: null,
            contactInfo: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            },
            ticketsBlocked: false,
            orderPlaced: false,
            finalized: false,
            blockToken: null,
            blockTimer: null,
            blockTimeStart: null,
            blockTimeElapsedSecs: null,
            warningMessage: null,
            errorMessage: null,
            // availabilityBlocker: null,
            isAdmin: null,
        };
    },

    computed: {
        flattenedEvents() {
            return this.uberEvents.flatMap(ue => [
                ue.linkToken && !ue.archived && ue.isListed && ue.from && !this.ueSellBlocker(ue) && !this.sfIsPast(ue) && {
                    uberEvent: ue,
                    blocker: this.sfAvailabilityBlocker(ue),
                },
                ...ue.events.map(e => e.linkToken && !e.archived && e.isListed && !this.ueSellBlocker(ue) && !this.eSellBlocker(e, ue) && !this.sfIsPast(ue, e) && {
                    uberEvent: ue,
                    event: e,
                    blocker: this.sfAvailabilityBlocker(ue, e),
                }),
            ]).filter(v => (
                v &&
                (!this.filterUETypes.length || this.filterUETypes.includes(v.uberEvent.type)) &&
                (!this.filterVenue || this.filterVenue.id === (v.event?.venueRef || v.uberEvent.venueRef))
            )).sort((a, b) => {
                const aTime = dayjs(a.event ? a.event.from : a.uberEvent.from).valueOf();
                const bTime = dayjs(b.event ? b.event.from : b.uberEvent.from).valueOf();

                return aTime - bTime;
            });
        },
        filteredFlattenedEvents() {
            if (this.feTextFilterFn)
                return this.flattenedEvents.filter(this.feTextFilterFn);

            return this.flattenedEvents;
        },
        uberEvent() {
            return this.flattenedEvent?.uberEvent;
        },
        event() {
            return this.flattenedEvent?.event;
        },
        availabilityBlocker() {
            return this.flattenedEvent?.blocker;
        },
        dateSelectEvents() {
            return this.uberEvent.events.filter(e => !e.archived && e.isListed && !this.eSellBlocker(e, this.uberEvent));
        },
        formattedVenue() {
            return this.loc(this.findById(this.venues, (this.event && this.event.venueRef) || this.uberEvent.venueRef).name);
        },
        formattedDate() {
            return this.formatDateRange(
                (this.event && this.event.from) || this.uberEvent.from,
                (this.event && this.event.to) || (!(this.event && this.event.from) && this.uberEvent.to),
                false,
                this.uberEvent.type !== 'festival' || this.event,
            );
        },
        showDateSelect() {
            return this.ueIs(this.uberEvent, 'multikoncert', 'divadlo') && this.event && this.uberEvent.events.length > 1;
        },
        listedEvents() {
            return !this.mockHide ?
                this.uberEvent.events :
                this.uberEvent.events.filter(e => !e.archived && e.isListed);
        },
        totalTicketCount() {
            return _.sumBy(this.eventSelections, this.getESTicketCount);
        },
        totalPrice() {
            return _.sumBy(this.eventSelections, this.getESSubTotal);
        },
        blockTimeRemaining() {
            const time = appConfig.storefront.blockTimeoutSecs - this.blockTimeElapsedSecs;

            return `${Math.floor(time / 60)}:${(time % 60) < 10 ? '0' : ''}${time % 60}`;
        },
        readyToSell() {
            return (
                (!this.event || !this.eSellBlocker(this.event, this.uberEvent)) &&
                !this.ueSellBlocker(this.uberEvent)
            );
        },
        readyToBlock() {
            return this.eventSelections && this.eventSelections.some(es => es.ticketSelections.some(ts => ts.count));
        },
        submitEnabled() {
            return (
                this.ticketsBlocked &&
                this.contactInfo.firstName &&
                this.contactInfo.lastName &&
                this.contactInfo.email && this.isEmail(this.contactInfo.email) &&
                this.contactInfo.phone && this.isPhone(this.contactInfo.phone)
            );
        },
        mockHide() {
            return appConfig.storefront.mockHide && this.isAdmin;
        },
        otcSessionCookieName() {
            return 'otcSession';
        },
    },

    watch: {
        flattenedEvent(val) {
            if (val) {
                this.init();
            }
        },
        // hasAvailableSlots(es){
        //     let counter = 0;
        //     return true

        // },
        eventSelections: {
            handler: function (val) {
                // Ignore changes made while populating from block token in cookie
                if (this.populating)
                    return;

                for (const es of val) {
                    const tss = es.ticketSelections;
                    const tgs = this.getTicketGroups(es);
                    const maxRowCount = _.sumBy(tgs, tg => this.getDiscountOptions(tg).length);
                    const extraTS = tss.find(ts => this.isRowRedundant(es, ts));

                    // Add new row when last existing row has count
                    if (tss.length < maxRowCount && _.last(tss).group && _.last(tss).count) {
                        tss.push({
                            group: (tgs.length === 1) ? tgs[0] : null,
                            discount: null,
                            count: null,
                        });
                    };

                    // Clear count of row made redundant by changing group or discount, and remove last row
                    if (extraTS && extraTS.count) {
                        extraTS.count = null;

                        tss.pop();
                    }
                }
            },
            deep: true,
        },
        readyToBlock(val) {
            if (!val && this.ticketsBlocked) {
                this.ticketsBlocked = false;
            }
        },
    },

    methods: {
        formatDateRangeShort(from, to) {
            if (!from)
                return null;

            const fd = dayjs(from);
            const td = dayjs(to);
            const yearFormat = fd.isSame(dayjs(), 'year') && (!to || td.isSame(dayjs(), 'year')) ? '' : ' YYYY';
            const format = `D. M.${yearFormat}`;

            if (!to || fd.isSame(td, 'day'))
                return fd.format(format);

            if (fd.isSame(td, 'month'))
                return `${fd.format('D.')} – ${td.format(format)}`;

            if (fd.isSame(td, 'year'))
                return `${fd.format('D. M.')} – ${td.format(format)}`;

            return `${fd.format(format)} – ${td.format(format)}`;
        },
        venueFilterFn(venue, text) {
            return this.loc(venue.name).toLowerCase().includes(text.toLowerCase());
        },
        formatThousands(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        getFlattenedEventLabel({ uberEvent, event }) {
            const date = this.formatDateRangeShort(event ? event.from : uberEvent.from, event ? event.to : uberEvent.to, false);
            const ueName = this.loc(uberEvent.name);
            const eName = this.loc(event?.name);

            return `${ueName}${eName ? ` – ${eName}` : ''} ... ${date}`;
        },
        onFlattenedEventFilter(val, update, abort) {
            // Split needle into space-delimited chunks
            const needles = this.removeAccents(val).toLowerCase().split(/ +/);

            update(() => {
                if (!val.length) {
                    // Include all options
                    this.feTextFilterFn = null;
                } else {
                    // Include option if at least one chunk is found
                    this.feTextFilterFn = v => needles.find(n => this.removeAccents(this.getFlattenedEventLabel(v)).toLowerCase().includes(n));
                }
            });
        },
        getEventSelectionName(event) {
            const name = this.loc(event.name);

            switch (this.uberEvent.type) {
                case 'festival':
                case 'vystava':
                    return name;

                case 'multikoncert':
                case 'divadlo':
                    return this.formatDate(event.from, false) + (name ? ` – ${name}` : '');
            }
        },
        tgSelectReadonly(es) {
            return this.getTicketGroups(es).length === 1;
        },
        isRowRedundant(eventSelection, ticketSelection) {
            for (const ts of eventSelection.ticketSelections) {
                if (ts === ticketSelection)
                    return false;

                if (ts.group === ticketSelection.group && ts.discount === ticketSelection.discount)
                    return true;
            }
        },
        getTicketGroups(eventSelection) {
            if (this.ticketGroup)
                return [this.ticketGroup];

            return this.getListedGroups(eventSelection.event || this.event || this.uberEvent);
        },
        getListedGroups(obj) {
            return obj.ticketGroups.filter(tg => this.tgxx(tg, 'isListed') && (!tg.templateRef || tg.inherit) && !this.tgSellBlocker(tg, this.uberEvent, this.event));
        },
        getESSubTotal(es) {
            return _.sumBy(es.ticketSelections, this.getTSSubTotal);
        },
        getTSSubTotal(ts) {
            return ts.count * this.getTicketSubTotal(ts);
        },
        getTicketSubTotal(ts) {
            if (!ts.count || !ts.group)
                return 0;

            const basePrice = this.tgxx(ts.group, 'price');
            const finalPrice = !ts.discount ? basePrice : ts.discount.replacementPrice || Math.round(basePrice * ts.discount.multiplier);

            return finalPrice;
        },
        getESTicketCount(es) {
            return _.sumBy(es.ticketSelections, ts => ts.count) || 0;
        },
        getTGSelectLabel(es) {
            const tgs = this.getTicketGroups(es);
            const name = this.tgxx(tgs[0], tg => this.loc(tg.name));

            return tgs.length === 1 && !name ? this.$t('storefront.price') : this.$t('storefront.category');
        },
        getTGHint(ticketGroup) {
            return !ticketGroup ? null : this.loc(ticketGroup.description);
        },
        getTGOptionLabel(ticketGroup) {
            const name = this.tgxx(ticketGroup, tg => this.loc(tg.name)) || this.tgxx(ticketGroup, 'label');
            const price = this.tgxx(ticketGroup, 'price');
            const soldOutStr = this.getTGAvailableCount(ticketGroup) ? '' : ` (${this.$t('storefront.sold_out')})`;

            return (name ? `${name}: ` : '') + this.$tc('price', price) + soldOutStr;
        },
        getTGPublicFrom(es, tg) {
            return this.tgPublicFrom(this.uberEvent, es.event || this.event, tg);
        },
        getTGPublicTo(es, tg) {
            return this.tgPublicTo(this.uberEvent, es.event || this.event, tg);
        },
        isTGPublic(es, tg) {
            return !this.mockHide || this.sfCheckPublicDates(this.uberEvent, es.event || this.event, tg);
        },
        getTGAvailableCount(tg) {
            return tg.availableCount || (this.tgxx(tg, 'ticketCount') - tg.blockedCount);
        },
        getTGTotalSelectedTicketCount(es, tg) {
            return _.sumBy(es.ticketSelections, ts => ts.group === tg && ts.count);
        },
        getCountOptions(es, tg, count) {
            if (!tg)
                return [];

            const totalMax = appConfig.storefront.maxTicketsPerSale;
            const maxFromTotalMax = totalMax - (this.totalTicketCount - count);
            const maxFromAvailable = this.getTGAvailableCount(tg) - (this.getTGTotalSelectedTicketCount(es, tg) - count);
            const max = Math.min(maxFromTotalMax, maxFromAvailable);
            const opts = _.range(1, max + 1);

            if (maxFromAvailable < totalMax) {
                opts.push(this.$t('storefront.only_few_tickets_remaining'));
            } else if (maxFromTotalMax < totalMax) {
                opts.push(this.$t('storefront.max_total_tickets_per_sale', { count: totalMax }));
            }

            return opts;
        },
        tgxx(ticketGroup, ...fieldsOrFns) {
            return this.tgx(ticketGroup, this.uberEvent, ...fieldsOrFns);
        },
        getDiscountOptions(ticketGroup) {
            const discounts = Helpers.methods.getDiscounts.call(this, ticketGroup, this.uberEvent);

            return [
                { value: null, label: this.$t('storefront.no_discount') },
                ...discounts.map(d => ({ value: d, label: `${this.loc(d.name)} (${this.getDiscountAmountStr(ticketGroup, d)})` })),
            ];
        },
        getDiscountAmountStr(ticketGroup, discount) {
            if (discount.replacementPrice)
                return `= ${this.$tc('price', discount.replacementPrice)}`;

            const basePrice = this.tgxx(ticketGroup, 'price');
            const finalPrice = Math.round(basePrice * discount.multiplier);
            const amount = finalPrice - basePrice;
            const sign = (amount > 0) ? '+' : '';

            return this.$tc('price', `${sign}${amount}`);
        },
        goToEvent(event) {
            this.flattenedEvent = this.flattenedEvents.find(fe => fe.uberEvent === this.uberEvent && fe.event === event);
        },
        getPublicFrom(event) {
            event = event || this.event;

            return event ? this.ePublicFrom(this.uberEvent, event)
                         : this.uePublicFrom(this.uberEvent);
        },
        getPublicTo(event) {
            event = event || this.event;

            return event ? this.ePublicTo(this.uberEvent, event)
                         : this.uePublicTo(this.uberEvent);
        },
        isEventPublic(event) {
            return !this.mockHide || this.sfCheckPublicDates(this.uberEvent, event);
        },
        makeEventSelection(event) {
            return {
                event,
                ticketSelections: [{
                    group: null,
                    discount: null,
                    count: null,
                }],
            };
        },
        collectSelectedTickets() {
            const tickets = [];

            for (const es of this.eventSelections) {
                for (const ts of es.ticketSelections) {
                    if (!ts.group || !ts.count)
                        continue;

                    for (let i = 0; i < ts.count; ++i) {
                        const ticket = {
                            eventRef: (es.event && es.event.id) || (this.event && this.event.id),
                            ticketGroupRef: ts.group.id,
                            globalDiscountRef: ts.discount && ts.discount.isGlobal ? ts.discount.id : null,
                            localDiscountRef: ts.discount && !ts.discount.isGlobal ? ts.discount.id : null,
                        };

                        tickets.push(ticket);
                    }
                }
            }

            return tickets;
        },
        toggleLanguage() {
            this.$router.push({ params: { lang: this.$i18n.locale !== 'cs' ? 'cs' : 'en' } });
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'cs' : 'en';
        },

        async blockAndProceed() {
            this.warningMessage = null;
            this.errorMessage = null;

            const tickets = this.collectSelectedTickets();

            try {
                this.blockToken = await this.apiBlockTickets(tickets);
            } catch (err) {
                this.$q.dialog({
                    message: _.get(err, 'response.data.key') === 'api.block.notEnoughTicketsAvailable'
                        ? this.$t('storefront.tickets_no_longer_available')
                        : this.$t('storefront.ticket_blocking_failed'),
                }).onOk(this.refreshDataAndReset);

                return;
            }

            const expiryInDays = appConfig.storefront.blockTimeoutSecs / 3600 / 24;

            Cookies.set(this.otcSessionCookieName, {
                uberEventRef: this.uberEvent.id,
                eventRef: this.event?.id,
                blockToken: this.blockToken,
            }, { expires: expiryInDays });

            this.startReleaseTimer(Date.now(), 0);
        },
        async releaseAndReturn() {
            this.warningMessage = null;
            this.errorMessage = null;

            clearTimeout(this.blockTimer);

            try {
                await this.apiReleaseTickets();
            } catch (err) {
                // Unexpected error, just reload
                window.location.reload();
            }

            this.blockToken = null;

            Cookies.remove(this.otcSessionCookieName);

            this.ticketsBlocked = false;
            this.orderPlaced = false;
            this.blockTimer = null;
        },
        async placeAndFinalizeOrder() {
            let response;

            this.$q.dialog({
                class:'my-dialog otc-success',
                title: 'Objednávka byla dokončena a vstupenky odeslány na zákazníkovu e-mailovou adresu.',
                message: 'Kliknutím na tlačítka níže otevřete PDF se vstupenkami k tisku nebo založíte novou objednávku.',
                cancel: true,
                ok: { label: "Založit novou objednávku", color: 'black' },
                cancel: { label: "Zobrazit vstupenky k tisku", color: 'null', textColor: "primary" },
            }).onOk(async () => {
                await this.startNewOrder()

            }).onCancel(async () => {
                await this.downloadAndOpenPdf()
                await this.startNewOrder()
            })

            try {
                if (this.orderPlaced) {
                    // Retry failed payment
                    response = await axios.post('/retry', {
                        uberEventRef: this.uberEvent.id,
                        blockToken: this.blockToken,
                    });
                } else {
                    // Bump sale status and try payment for the first time
                    response = await axios.post('/order', {
                        uberEventRef: this.uberEvent.id,
                        blockToken: this.blockToken,
                        contactInfo: this.contactInfo,
                        isOtc: true,
                    });
                }
            } catch (err) {
                this.$q.dialog({
                    message: this.$t('storefront.place_order_failed'),
                }).onOk(() => window.location.reload());

                return;
            }

            this.ticketsBlocked = false;
            this.orderPlaced = true;
            this.finalized = true;
        },
        async downloadAndOpenPdf() {
            const { data } = await axios.get('/pdf', {
                params: { blockToken: this.blockToken },
                responseType: 'blob',
            });

            this.openBlobInNewTab(data, 'application/pdf');
        },
        async startNewOrder() {
            await Cookies.remove(this.otcSessionCookieName);

            window.location.reload();
        },

        async apiBlockTickets(tickets) {
            const response = await axios.post('/block', {
                uberEventRef: this.uberEvent.id,
                tickets,
            });

            return response.data;
        },
        async apiReleaseTickets() {
            await axios.post('/release', {
                uberEventRef: this.uberEvent.id,
                blockToken: this.blockToken,
            });
        },

        startReleaseTimer(startTimeMillis, elapsedSecs) {
            this.blockTimeStart = startTimeMillis;
            this.blockTimeElapsedSecs = elapsedSecs;
            this.ticketsBlocked = true;

            const countDown = () => {
                const elapsedMillis = Date.now() - this.blockTimeStart;
                const idealElapsedMillis = this.blockTimeElapsedSecs * 1000;
                const diff = elapsedMillis - idealElapsedMillis;

                this.blockTimeElapsedSecs += 1;

                if (this.blockTimeElapsedSecs === appConfig.storefront.blockTimeoutSecs) {
                    this.$q.dialog({
                        class:'my-dialog expired',
                        title: this.$t('storefront.block_expired_title'),
                        message: this.$t('storefront.block_expired_text'),
                        cancel: true,
                        ok: { label: this.$t('yes') },
                        cancel: { label: 'Storno', color: null, textColor: 'primary' },
                    }).onOk(async () => {
                        await this.releaseAndReturn();

                        this.blockAndProceed();
                    }).onCancel(async () => {
                        await this.releaseAndReturn();
                    });
                } else if (this.ticketsBlocked) {
                    this.blockTimer = setTimeout(countDown, 1000 - diff);
                } else {
                    this.blockTimer = null;
                }
            };

            countDown();
        },
        clearSelected(targetTs,targetEs, esIdx, tsIdx) {
            if(targetEs.ticketSelections.length<2) {
                targetTs.count = 0;
                return;
            }
            if(!targetTs.count) {
                return
            }
            if(this.eventSelections.length === 1 && this.eventSelections[0].ticketSelections.length < 2) {
                targetTs.count = 0;
                return;
            }
            if(targetTs.count=== 10){
                targetTs.count = 0;
                return;
            }
            this.eventSelections[esIdx].ticketSelections.splice(tsIdx, 1);
        },
        async refreshDataAndReset() {
            // Hack to force availability data refresh
            this.lastFetch.uberEvents = null;

            await this.fetchData();

            this.flattenedEvent = this.flattenedEvents.find(fe => fe.uberEvent.id === this.uberEvent.id && fe.event?.id === this.event?.id);

            this.init();
        },
        async fetchData() {
            await this.fetchAll('settings');
            await this.fetchMany('uberEvents', 'venues', 'promoters', 'globalDiscounts');
        },
        init() {
            // Mock-hide only: check if ready to sell
            // if (this.mockHide && this.flattenedEvent && !this.readyToSell) {
            //     this.flattenedEvent = null;
            // }

            // Check if currently available
            // if (this.mockHide) {
            //     this.availabilityBlocker = !this.uberEvent ? 'not_ready' : this.sfAvailabilityBlocker(this.uberEvent, this.event);
            // }

            // Terminate loading if no uber-event
            // if (!this.uberEvent) {
            //     this.loading = false;
            //     return;
            // }

            // Initialize ticket selections (inside event selections)
            if (!this.event && !this.ticketGroup && this.listedEvents.length) {
                // Uber-event with one or more listed events
                this.eventSelections = this.listedEvents.map(this.makeEventSelection);

                // Prepend uber-event ticket groups if any
                if ((this.uberEvent.ticketGroups || []).length) {
                    this.eventSelections.unshift(this.makeEventSelection(null));
                }
            } else {
                // Ticket group, event, or uber-event without listed events
                this.eventSelections = [this.makeEventSelection(null)];
            }

            // If there is only one ticket group in an event selection, pre-select it
            for (const es of this.eventSelections) {
                const tgs = this.getTicketGroups(es);

                if (tgs.length === 1) {
                    es.ticketSelections[0].group = tgs[0];
                }
            }

            // this.loading = false;
        },
        async populateFromBlockToken(blockToken) {
            let sale;

            try {
                const response = await axios.get('/sale', { params: { blockToken } });

                sale = response.data;
            } catch (err) {
                // Error notification handled via interceptor
            }

            if (!sale)
                return;

            this.populating = true;

            switch (sale.status) {
                case 'paid':
                    this.blockToken = sale.blockToken;
                    this.orderPlaced = true;
                    this.finalized = true;

                    break;

                case 'expired':
                    Cookies.remove(this.otcSessionCookieName);

                    this.warningMessage = this.$t('storefront.sale_expired');

                    break;

                case 'ordered':
                    this.blockToken = sale.blockToken;
                    this.ticketsBlocked = true;
                    this.orderPlaced = true;

                    const order = _.last(sale.payuData);

                    if (order && order.status === 'CANCELED') {
                        this.errorMessage = this.$t('storefront.payment_error');
                    }

                    if (order && order.status === 'PENDING') {
                        this.warningMessage = this.$t('storefront.payment_pending');

                        setTimeout(() => window.location.reload(), 15000);
                    }

                    break;

                case 'blocked':
                    this.blockToken = sale.blockToken;
                    this.ticketsBlocked = true;

                    break;
            }

            if (this.blockToken) {
                // Remove initial empty ticket selections
                // (except for event selections not included in sale)
                for (const es of this.eventSelections) {
                    if (!es.event && sale.tickets.find(t => !t.eventRef) ||
                        (es.event || this.event) && sale.tickets.find(t => t.eventRef === (es.event || this.event).id)) {

                        es.ticketSelections = [];
                    }
                }

                // Populate ticket selections from sale tickets
                for (const ticket of sale.tickets) {
                    const es = (this.event || this.ticketGroup)
                        ? this.eventSelections[0]
                        : this.eventSelections.find(s => (!s.event && !ticket.eventRef) || (s.event && s.event.id == ticket.eventRef));

                    let ts = es.ticketSelections.find(s => s.group && s.group.id === ticket.ticketGroupRef && (
                        (!s.discount && !ticket.globalDiscountRef && !ticket.localDiscountRef) ||
                        (s.discount && s.discount.isGlobal && s.discount.id === ticket.globalDiscountRef) ||
                        (s.discount && !s.discount.isGlobal && s.discount.id === ticket.localDiscountRef)
                    ));

                    if (ts) {
                        ts.count += 1;
                    } else {
                        const ue = this.uberEvent;
                        const groups = (ticket.eventRef ? this.findById(ue.events, ticket.eventRef) : ue).ticketGroups;

                        ts = {
                            group: this.findById(groups, ticket.ticketGroupRef),
                            discount:
                                ticket.globalDiscountRef ? this.findById(this.globalDiscounts, ticket.globalDiscountRef) :
                                ticket.localDiscountRef ? this.findById(ue.localDiscounts, ticket.localDiscountRef) : null,
                            count: 1,
                        };

                        es.ticketSelections.push(ts);
                    }
                }

                if (sale.contactInfo) {
                    this.contactInfo = sale.contactInfo;
                }

                if (this.ticketsBlocked) {
                    this.startReleaseTimer(sale.blocked, dayjs().diff(sale.blocked, 'second'));
                }
            }

            this.populating = false;
        },
    },

    @cancellableFetch
    async created() {
        this.$i18n.locale = this.$route.params.lang;
        axios.defaults.headers.common['X-Meet-Lang'] = this.$route.params.lang;

        this.isAdmin = localStorage.getItem('authRole') === 'admin';

        await this.fetchData();

        const payload = Cookies.get(this.otcSessionCookieName);

        if (payload) {
            const { uberEventRef, eventRef, blockToken } = JSON.parse(payload);

            this.flattenedEvent = this.flattenedEvents.find(fe => fe.uberEvent.id === uberEventRef && fe.event?.id === eventRef);

            this.init();

            await this.populateFromBlockToken(blockToken);
        }
    },

    meta() {
        return {
            title: this.event ? this.loc(this.event.name) : this.uberEvent ? this.loc(this.uberEvent.name) : this.$t('otc.title'),
        };
    },
};
</script>

<style lang="scss">

.select-venues {
    cursor: pointer;

    .q-field__inner {
        height: 90px;

        .q-field__append {
            height: 90px;
        }

        .q-field__label {
            font-size: 16px !important;
            top: 34px !important;
        }
    }
}

.checkbox__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 8px;

    .otc__checkbox__wrapper {
        position: relative;
        height: 90px;
        background-color: #fff;
        border: 1px solid $black-300;
        gap: 24px;
        overflow: hidden;

        &.active {
            border: 2px solid $black-900;
            background-color: $black-100;
        }

        .otc__checkbox {
            opacity: 0;

            .q-checkbox__inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: none;
                border: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }
    }
}

.card-multiple{
    background: $black-100;
    border-radius: 0;
    padding: 24px 24px 40px;
    border: 1px solid $black-300;
}

.fields {
    max-width: 960px;
}

.tg-non-public .q-field__native {
    color: $grey;
}

.unavailable .q-field__native {
    color: $grey-6;
}

.tree-wrapper {
    &.closed {
        width: 84px;
    }
}

.tree-scroller {
    max-height: calc(100vh - 180px);
    overflow-y: scroll;
}
</style>
